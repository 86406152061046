import React, { useEffect } from "react"
import { graphql } from "gatsby"
import HomePost from "../components/HomePost"
import Layout from "../components/Layout"
import { PostTitle } from "../styles/base"
import { hiddenPost } from "../constants"

const HomeBlogList = props => {
  const postList = props.pageContext.posts  
  useEffect(()=>{
    window.parent.iframeLoaded();
  }, []);

  return (
  <Layout insideIframe='true'>
    <PostTitle className="welcome">Blog</PostTitle>
    {postList.map(({ node }, i) => (!hiddenPost.includes(node.fields.slug)) && (
      <HomePost
        key={i}
        slug={node.fields.slug}
        title={node.frontmatter.title}
        timeToRead={node.timeToRead}
        date={node.frontmatter.date}
        main_class={node.frontmatter.main_class}
      />
    ))}
  </Layout>
  )
}

export default HomeBlogList

export const HomePostListQuery = () => graphql`
  query HomePostListQuery {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
            title
          }
          timeToRead
        }
      }
    }
  }
`