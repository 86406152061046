import React from "react"
import PropTypes from "prop-types"

import * as S from "./styled"

const HomePost = ({
  slug,
  timeToRead,
  title,
}) => {
  return (
    <S.HtmlLink href={slug} target="_top">
      <S.HomePostWrapper>
        <S.HomePostInfo>
          <S.HomePostTitle>{title}</S.HomePostTitle>
          <S.HomePostDate>
            {timeToRead && `${timeToRead} min de leitura`}
          </S.HomePostDate>
        </S.HomePostInfo>
      </S.HomePostWrapper>
    </S.HtmlLink>
  )
}

HomePost.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  timeToRead: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default HomePost
